import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import numeral from 'numeral';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// Actions
import { gameActions } from '../../actions';

import { Tooltip } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list';
// import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: null,
      tip: false,
    };

    // Bindings
    this.rowClick = this.rowClick.bind(this);
    this.toggleTip = this.toggleTip.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      dispatch(gameActions.fetchGames(match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  rowClick(data, index) {
    this.setState({ group: data });
  }

  toggleTip() {
    this.setState({ tip: !this.state.tip });
  }

  render() {
    const { game } = this.props;
    const players = game.data.players || [];

    const playersList = players.map((player) => {
      const quarters = [0, 0, 0, 0];
      const net_revenue = player.results.reduce((acc, r) => {
        quarters[r.level.id - 1] += r.revenue;
        return acc + (r.revenue < 0 ? 0 : r.revenue);
      }, 0);
      const performance = player.results.reduce((acc, r) => {
        return acc + r.performance;
      }, 0);
      const total_performance =
        performance / (player.results.length > 0 ? player.results.length : 1);

      const lost =
        player.results.length > 0
          ? player.results.reduce((acc, r) => {
              return acc + r.lost;
            }, 0)
          : 0;

      return {
        name: player.name,
        lastname: player.lastname,
        email: player.email,
        quarter1: {
          value: quarters[0],
          label: numeral(quarters[0]).format('$0,0'),
        },
        quarter2: {
          value: quarters[1],
          label: numeral(quarters[1]).format('$0,0'),
        },
        quarter3: {
          value: quarters[2],
          label: numeral(quarters[2]).format('$0,0'),
        },
        quarter4: {
          value: quarters[3],
          label: numeral(quarters[3]).format('$0,0'),
        },
        revenue: {
          value: net_revenue,
          label: numeral(net_revenue).format('$0,0'),
        },
        performance: {
          value: total_performance,
          label: numeral(total_performance).format('0,0%'),
        },
        lost: {
          value: lost,
          label: numeral(lost).format('0,0'),
        },
      };
    });

    // console.log({ playersList });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Leaderboard
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3' style={{ fontSize: '14px' }}>
          <List
            labels={[
              'Name',
              'Surname',
              'Email',
              'Q1',
              'Q2',
              'Q3',
              'Q4',
              'Net Revenue',
              <span>
                Performance{' '}
                <FontAwesomeIcon
                  id='tip'
                  icon='question-circle'
                  size='1x'
                  className='icon bg-white'
                />
              </span>,
              'Lost Customers',
            ]}
            fields={[
              'name',
              'lastname',
              'email',
              'quarter1',
              'quarter2',
              'quarter3',
              'quarter4',
              'revenue',
              'performance',
              'lost',
            ]}
            sortables={[
              'name',
              'lastname',
              'email',
              'quarter1',
              'quarter2',
              'quarter3',
              'quarter4',
              'revenue',
              'performance',
              'lost',
            ]}
            data={playersList}
            onRowClick={this.rowClick}
          />
        </div>

        <Tooltip isOpen={this.state.tip} target='tip' toggle={this.toggleTip}>
          Performance is defined as the number times a learner correctly managed
          individual demand dynamics divided by the total number of times these
          demand dynamics were brought to bear (three times per season).
        </Tooltip>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
