import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

// Images
import image1 from '../../assets/function-1.png';
import image2 from '../../assets/function-2.png';
import image3 from '../../assets/function-3.png';

class Debrief extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { game } = this.props;
    const levels = game.data?.levels;
    const options = ['fish', 'meat', 'starch', 'salad'];

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Decision Criteria
          <small class='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>
        <div className='mt-3 pb-5 '>
          <div className='row pt-3 pb-5'>
            <div className='col text-center'>
              <div className='card shadow border-0 mb-4'>
                <div className='card-body '>
                  <h5 className='mb-4'>Demand Characteristics</h5>
                  <div className='row'>
                    <div className='col'>
                      <b>Bait and Switch</b>
                      <p className='mt-2'>
                        Seafood is at the core of the restaurant's cousine. If
                        no menu slots contain fish, some potential customers
                        will walk away.
                      </p>
                    </div>
                    <div className='col'>
                      <b>Single Fish Fatigue</b>
                      <p className='mt-2'>
                        Customers in this market prefer some variety. If only
                        one menu slot is assigned to fish, some potential
                        customers will walk away.
                      </p>
                    </div>
                    <div className='col'>
                      <b>Inadequate Options</b>
                      <p className='mt-2'>
                        Some customers have dietary restrictions. If the menu
                        does not contain one of each type of item, some
                        potential customers will walk away.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card shadow border-0'>
                <div className='card-body'>
                  <h5 className='mb-4'>Product Profitability</h5>
                  <table className='table mb-0'>
                    <thead>
                      <tr>
                        <th>Dish</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                      </tr>
                    </thead>
                    <tbody>
                      {options.map((o) => (
                        <tr>
                          <td className='align-middle text-capitalize'>{o}</td>
                          {levels.map((l) => (
                            <td>
                              <div className='text-muted mb-2'>
                                <small>
                                  Price: ${l[o].price} &nbsp;&nbsp;&nbsp;&nbsp;
                                  Cost: ${l[o].cost}
                                </small>
                              </div>
                              Net Revenue: ${l[o].price - l[o].cost}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(Debrief);
