import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import numeral from 'numeral';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import infoModal from '../../assets/info-modal-1.png';

class DebriefDistribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      popup: false,
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { popup } = this.state;
    const { game } = this.props;
    const gameData = game.data;
    const options = ['fish', 'meat', 'starch', 'salad'];

    const levels = gameData.players.reduce(
      (acc, player) => {
        const playres = player.results.reduce(
          (pacc, r) => {
            return r.menu.reduce(
              (macc, m) => {
                pacc[options[m]][r.level.id - 1]++;
                return pacc;
              },
              {
                fish: [0, 0, 0, 0],
                meat: [0, 0, 0, 0],
                starch: [0, 0, 0, 0],
                salad: [0, 0, 0, 0],
              }
            );
          },
          {
            fish: [0, 0, 0, 0],
            meat: [0, 0, 0, 0],
            starch: [0, 0, 0, 0],
            salad: [0, 0, 0, 0],
          }
        );

        console.log({ playres });

        // Add this player's results
        acc.fish = acc.fish.map((n, nidx) => n + playres.fish[nidx]);
        acc.meat = acc.meat.map((n, nidx) => n + playres.meat[nidx]);
        acc.starch = acc.starch.map((n, nidx) => n + playres.starch[nidx]);
        acc.salad = acc.salad.map((n, nidx) => n + playres.salad[nidx]);

        // Return
        return acc;
      },
      {
        fish: [0, 0, 0, 0],
        meat: [0, 0, 0, 0],
        starch: [0, 0, 0, 0],
        salad: [0, 0, 0, 0],
      }
    );

    // Avgs
    // const avgs = levels;
    const avgs = {
      fish: levels.fish.map(
        (n) =>
          n /
          (gameData.players.filter((p) => p.results.length > 0).length > 0
            ? gameData.players.filter((p) => p.results.length > 0).length
            : 1)
      ),
      meat: levels.meat.map(
        (n) =>
          n /
          (gameData.players.filter((p) => p.results.length > 0).length > 0
            ? gameData.players.filter((p) => p.results.length > 0).length
            : 1)
      ),
      starch: levels.starch.map(
        (n) =>
          n /
          (gameData.players.filter((p) => p.results.length > 0).length > 0
            ? gameData.players.filter((p) => p.results.length > 0).length
            : 1)
      ),
      salad: levels.salad.map(
        (n) =>
          n /
          (gameData.players.filter((p) => p.results.length > 0).length > 0
            ? gameData.players.filter((p) => p.results.length > 0).length
            : 1)
      ),
    };

    // console.log({ levels });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Menu Distribution{' '}
          {/* <FontAwesomeIcon
            className='ml-1 text-secondary'
            style={{ fontSize: '1.25rem', cursor: 'pointer' }}
            icon='question-circle'
            onClick={() => this.setState({ popup: !popup })}
          />{' '} */}
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3'>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: { text: '' },
              chart: { type: 'column', height: '500px' },
              credits: { enabled: false },
              legend: { enabled: true },
              xAxis: {
                categories: [...gameData.levels.map((l) => l.name)],
              },
              yAxis: {
                labels: { style: { fontSize: '14px' } },
                allowDecimals: false,
                min: 0,
                title: {
                  text: 'Average Item Featured Count',
                },
                stackLabels: {
                  enabled: true,
                  style: {
                    fontWeight: 'bold',
                    color:
                      (Highcharts.theme && Highcharts.theme.textColor) ||
                      'gray',
                  },
                  formatter: function () {
                    return Math.round(this.total);
                  },
                },
              },
              tooltip: {
                enabled: true,
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat:
                  'In this quarter, total number of menu slots assigned to {series.name}: {point.y:.1f}',
              },
              plotOptions: {
                column: {
                  stacking: 'normal',
                  dataLabels: {
                    enabled: true,
                    format: '{point.y:.1f}',
                    //color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                  },
                },
              },
              series: [
                {
                  name: 'Fish',
                  data: avgs.fish,
                  // data: levels.fish.map((n) => n / levels.fish?.length),
                },
                {
                  name: 'Meat',
                  data: avgs.meat,
                  // data: levels.meat.map((n) => n / levels.fish?.length),
                },
                {
                  name: 'Starch',
                  data: avgs.starch,
                  // data: levels.starch.map((n) => n / levels.fish?.length),
                },
                {
                  name: 'Salad',
                  data: avgs.salad,
                  // data: levels.salad.map((n) => n / levels.fish?.length),
                },
              ],
            }}
          />
        </div>

        <Modal
          isOpen={popup}
          toggle={() => this.setState({ popup: !popup })}
          centered={true}
          size={'lg'}>
          <ModalHeader>Item Selection</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='col-6 text-center'>
                <img src={infoModal} alt='Plotted Function' />
                <table
                  className='table table-sm table-bordered mmb-0 text-center'
                  style={{
                    width: '50%',
                    margin: '20px auto 0',
                    fontSize: '.9rem',
                  }}>
                  <tbody>
                    <tr>
                      <td id=''>
                        <strong>$</strong>
                      </td>
                      <td id='demand0'>0</td>
                      <td id='demand1'>30</td>
                      <td id='demand2'>60</td>
                      <td id='demand3'>90</td>
                      <td id='demand4'>120</td>
                      <td id='demand5'>150</td>
                    </tr>
                    <tr>
                      <td id=''>
                        <strong>K</strong>
                      </td>
                      <td id='price0'>1108</td>
                      <td id='price1'>29,730</td>
                      <td id='price2'>72,010</td>
                      <td id='price3'>85,250</td>
                      <td id='price4'>97,320</td>
                      <td id='price5'>91,230</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='col-6'>
                <ul className='mt-3 mb-0'>
                  <li className='mb-3'>
                    Students are presented with a chart of estimated demand, or
                    a table with similar information.
                  </li>
                  <li className='mb-3'>
                    Students can quickly identify the item with the greatest
                    estimated revenue, for most items.
                  </li>
                  <li>
                    However, some items have very similar data visualizations.
                    In these cases, students are can use the functions provided
                    to identify the optimal item to select.
                  </li>
                </ul>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className='btn btn-outline-primary'
              onClick={() => this.setState({ popup: false })}>
              Close
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(DebriefDistribution);
